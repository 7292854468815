<template>
  <div class="topNav container-fluid">
    <div class="mobileInner">
      <div :class="{hidden: !isRoot}">
        <router-link to="/">
          <img :src="logo" class="topLogo" alt="" />
        </router-link>
      </div>
      <div :class="{hidden: isRoot}">
        <router-link to="/">
          <div class="textHome">
            <b-icon icon="chevron-left" scale="1" />
            <span> {{ pageName }} </span>
          </div>
        </router-link>
      </div>
      <div class="buttonLayer">
        <b-button squared variant="outline-white" v-b-toggle.sidebar-backdrop class="ham">
          <b-icon icon="list" font-scale="2" />
        </b-button>

        <b-sidebar
          id="sidebar-backdrop"
          backdrop
          shadow
          right
          width="80%"
          no-header-close
          @shown="menuShown"
          @hidden="menuHidden"
        >
          <div class="text-center">
            <a href="#"
              ><span @click="langSelected('KOREAN')"><small>KOREAN</small></span> |
            </a>
            <a href="#"
              ><span @click="langSelected('ENGLISH')"><small>ENGLISH</small></span>
              <!--              |-->
            </a>
            <!--            <a href="#"-->
            <!--              ><span @click="langSelected('JAPANESE')"><small>JAPANESE</small></span></a-->
            <!--            >-->
          </div>

          <div>
            <div class="menuContent"></div>
            <ul class="headerMenu">
              <li v-for="(header, index) in headers" :key="index">
                <b-link :href="header.topMenuLink">
                  <span>{{ header.topMenu }}</span>
                </b-link>
              </li>
              <!--              <li-->
              <!--                v-for="(header, index) in headers"-->
              <!--                :key="index"-->
              <!--                @click="menuClick(index)"-->
              <!--                :class="{on: index === selectedSubmenuIndex}"-->
              <!--              >-->
              <!--                <a href="#" target="_self" class="">-->
              <!--                  <span>{{ header.topMenu }}</span>-->
              <!--                </a>-->
              <!--                <TransitionCollapseHeight v-show="index === selectedSubmenuIndex">-->
              <!--                  <ul class="depth2">-->
              <!--                    <li v-for="(sub, index2) in header.subMenu" :key="index2">-->
              <!--                      <router-link :to="sub.link">-->
              <!--                        <span>{{ sub.text }}</span>-->
              <!--                      </router-link>-->
              <!--                    </li>-->
              <!--                  </ul>-->
              <!--                </TransitionCollapseHeight>-->
              <!--              </li>-->
            </ul>
          </div>
        </b-sidebar>
      </div>
    </div>
  </div>
</template>

<script>
// import TransitionCollapseHeight from '@/components/TransitionCollapseHeight';
import EventBus from '@/util/EventBus';
export default {
  name: 'GnbTop',
  // components: {TransitionCollapseHeight},
  props: {
    headers: Array,
    logo: String,
  },
  data() {
    return {
      isRoot: false,
      componentName: '',
      selectedSubmenuIndex: -1,
    };
  },
  computed: {
    pageName() {
      return this.$store.getters['Display/pageName'];
    },
    isShowChangeLang() {
      if (window.location.hostname.startsWith('localhost') || window.location.hostname.startsWith('devel-')) {
        return true;
      } else return false;
    },
  },
  watch: {
    $route(to, from) {
      this.componentName = this.$route.name;
      this.isRoot = this.$route.path === '/';
    },
  },
  mounted() {
    console.log('EventBus', this.$route, this.$router);
    this.componentName = this.$route.name;
    this.isRoot = this.$route.path === '/';
  },
  methods: {
    langSelected(lang) {
      this.selectedLanguage = lang;

      if (lang === 'KOREAN') {
        this.$root.$i18n.locale = 'ko';
      } else if (lang === 'ENGLISH') {
        this.$root.$i18n.locale = 'en';
      } else if (lang === 'JAPANESE') {
        this.$root.$i18n.locale = 'ja';
      }
      if (this.$router.currentRoute.path !== '/') {
        this.$router.replace('/');
      }
    },
    menuClick(idx) {
      if (this.selectedSubmenuIndex === idx) {
        this.selectedSubmenuIndex = -1;
      } else {
        this.selectedSubmenuIndex = idx;
      }
    },
    menuShown() {
      document.documentElement.style.overflow = 'hidden';
    },
    menuHidden() {
      document.documentElement.style.overflow = 'auto';
    },
  },
};
</script>

<style scoped lang="scss">
// RESET CSS
div,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

.hidden {
  display: none;
}

//
a {
  background: transparent;
  text-decoration: none;
  color: inherit;
}

.mobileInner {
  height: 5rem;
  line-height: 3rem;
  display: flex;
}

.topLogo {
  display: block;
  padding-left: 1rem;
  height: 2.5rem;
  margin-top: 1.1rem;
  box-sizing: border-box;
}

.buttonLayer {
  position: relative;
  margin-left: auto;
  margin-right: 0;
}

.ham {
  margin-top: 1rem;
}

// 상단 콘텐츠
.menuContent {
  height: 5rem;
  background-color: red;
}

// 접이식 메뉴
.headerMenu {
  float: none;
  display: block;
}

.headerMenu > li {
  position: relative;
  float: none;
  display: block;
  font-size: 1rem;
  width: 100%;
  text-align: left;
  max-width: 100%;
}

.headerMenu > li > a {
  display: block;
  padding: 1rem 1rem;
  white-space: nowrap;
  color: #000;
  border-bottom: 1px solid #ebebeb;
  font-size: 1.1rem;
}

.headerMenu > li.on a > span:after {
  margin-top: 0.6rem;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.depth2 {
}

.headerMenu > li > .depth2 li > a {
  color: #666;
  font-size: 1rem;
  display: block;
  padding: 0rem 1rem;
  border-bottom: 1px solid #ebebeb;
}

//.headerMenu > li > a > span:after {
//  display: block;
//  content: '';
//  position: absolute;
//  top: 1.5rem;
//  right: 1rem;
//  width: 1rem;
//  height: 1rem;
//  content: '';
//  border: solid #999;
//  border-width: 0 1px 1px 0;
//  margin: 0;
//  transform: rotate(405deg);
//  -webkit-transform: rotate(405deg);
//}

.headerMenu > li.on a > span:after {
  top: 2.3rem;
  margin: 0;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

///

.textHome {
  margin-top: 1rem;
  padding-left: 1rem;
  font-size: 1.4rem;
}

.topNav {
  display: none;
}

@media (max-width: 992px) {
  .topNav {
    display: block;
    position: fixed;
    background-color: white;
    z-index: 10000;
    border-bottom: 1px solid #e5e5e5;
  }
  .menuContent {
    display: none;
  }
}
</style>
