<template>
  <div>
    <div class="footer">
      <div class="footer-menu-layout">
        <div class="container">
          <nav class="footer-menu">
            <ul class="footermenu-list" v-show="isLang === 0">
              <li>
                <router-link to="/p/Introducepinx">회사소개</router-link>
              </li>
              <li>
                <router-link to="/p/Pinxpolicy/0" style="font-weight: bold">개인정보 처리 방침</router-link>
              </li>
              <li>
                <router-link to="/p/Pinxpolicy/1">이용약관</router-link>
              </li>
              <li>
                <router-link to="/p/Pinxpolicy/3">이메일 무단 수집 거부</router-link>
              </li>
              <li>
                <a href="https://ethics.sk.co.kr/" target="_blank">윤리경영</a>
                <!--                <router-link to="/p/Pinxpolicy/2">윤리경영</router-link>-->
              </li>
              <li>
                <router-link to="/p/QnaMain">고객의 소리</router-link>
              </li>
              <!--              <li v-show="isLang === 0">-->
              <!--                <a href="https://ethics.sk.co.kr/" target="_blank">상담&제보</a>-->
              <!--              </li>-->
              <li>
                <router-link to="/p/Direction">오시는 길</router-link>
              </li>
              <li>
                <a href="https://skpinx.skcareers.com/" target="_blank">인재채용</a>
              </li>
            </ul>

            <ul class="footermenu-list" v-show="isLang === 1">
              <li>
                <router-link to="/p/Pinxpolicy/0" style="font-weight: bold">Privacy Policy</router-link>
              </li>
              <li>
                <router-link to="/p/Pinxpolicy/2">Ethical Management</router-link>
              </li>
              <li>
                <a href="https://ethics.sk.co.kr/" target="_blank">Speak up & Report</a>
                <!--                <router-link to="/p/Pinxpolicy/2">윤리경영</router-link>-->
              </li>

              <!--              <li v-show="isLang === 0">-->
              <!--                <a href="https://ethics.sk.co.kr/" target="_blank">상담&제보</a>-->
              <!--              </li>-->
              <li>
                <router-link to="/p/Direction">Location</router-link>
              </li>
            </ul>

            <div class="footer-button" v-show="isLang === 0">
              <button type="button" class="btn btn-primary footer-button-left">
                <router-link to="/p/EventMain" style="color: white"
                  ><strong>행사문의</strong
                  ><b-icon
                    icon="arrow-right-square"
                    font-scale="1.5"
                    style="margin-left: 5px; padding-top: 3px"
                  ></b-icon
                ></router-link>
              </button>
              <button type="button" class="btn btn-primary footer-button-right">
                <router-link to="/p/MarketingMain" style="color: white"
                  ><strong>마케팅 제휴</strong
                  ><b-icon
                    icon="arrow-right-square"
                    font-scale="1.5"
                    style="margin-left: 5px; padding-top: 3px"
                  ></b-icon
                ></router-link>
              </button>
            </div>
          </nav>
        </div>
      </div>
      <div class="container mt-4">
        <div class="row">
          <div class="col-lg-2 footer-main-logo">
            <img src="@/assets/images/common/logo/logo_pinx_white.png" />
          </div>
          <div class="col-lg-10">
            <div class="d-flex">
              <div class="col-2 footer-other-logo">
                <a href="https://pinxgc.thepinx.co.kr" target="_blank"
                  ><img src="@/assets/images/common/logo/logo_golf_row_white.png"
                /></a>
              </div>
              <div class="col-2 footer-other-logo">
                <a href="https://podo.thepinx.co.kr" target="_blank"
                  ><img src="@/assets/images/common/logo/logo_podo_row_white.png"
                /></a>
              </div>
              <div class="col-2 footer-other-logo">
                <a href="https://theannex.thepinx.co.kr" target="_blank">
                  <img src="@/assets/images/common/logo/logo_annex_row_white.png"
                /></a>
              </div>
              <div class="col-2 footer-other-logo">
                <a href="https://pleisure.thepinx.co.kr" target="_blank">
                  <img src="@/assets/images/common/logo/logo_pleisure_row_white.png"
                /></a>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div class="row d-flex justify-content-left p-2">
              <div class="footer-list col-lg-9">
                <p style="line-height: 2" v-html="$t('footer_desc1')"></p>
                <p>Copyright 2022. SKPINX Co.,Ltd. All Rights Reserved.</p>
              </div>
              <div class="d-flex col-lg-3 justify-content-end">
                <div>
                  <!--                  <a href="https://www.facebook.com/profile.php?id=100015551734170" title="Facebook"
                    ><b-icon scale="2%" icon="facebook" class="footer-bi"></b-icon
                  ></a>
                  <a href="https://www.instagram.com/theannex_official/" title="Instagram"
                    ><b-icon scale="2%" icon="instagram" class="footer-bi"></b-icon
                  ></a>
                  <a href="https://www.youtube.com/channel/UCCSZhYIdT8wPlRk2lVMxtyw" title="Youtube"
                    ><b-icon scale="2%" icon="youtube" class="footer-bi"></b-icon
                  ></a>-->
                  <a href="https://www.instagram.com/theannex_official/" title="Instagram" target="_blank">
                    <button type="button" class="btn btn-circle mr-2">
                      <b-icon scale="2%" icon="instagram" style="color: white"></b-icon></button
                  ></a>
                  <a href="https://www.facebook.com/profile.php?id=100015551734170" title="Facebook" target="_blank">
                    <button type="button" class="btn btn-circle mr-2">
                      <b-icon scale="2%" icon="facebook" style="color: white"></b-icon></button
                  ></a>
                  <a href="https://www.youtube.com/channel/UCCSZhYIdT8wPlRk2lVMxtyw" title="Youtube" target="_blank">
                    <button type="button" class="btn btn-circle">
                      <b-icon scale="2%" icon="youtube" style="color: white"></b-icon></button
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end footer-->
  </div>
</template>

<script>
export default {
  name: 'PinxFooter',
  data() {
    return {
      isLang: 0,
    };
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.footer-main-logo {
  padding: 7rem 2rem 2rem 2rem;
}
.footer-other-logo {
  //margin-left: 0em;
  img {
    width: 80%;
  }
}
.footer {
  padding-bottom: 60px;
  padding-top: 0px;
  background-color: #121212;
  color: #686868;
}
.footer .footer-list {
  font-size: 12px;
}

ul {
  padding-left: 0em;
  padding-right: 0em;
}

li {
  list-style-type: none;
}

img {
  width: 100%;
}

hr {
  display: block;
  border-style: solid;
  border-width: 1px;
  width: 100%;
}
.footer-menu-layout {
  background-color: #fff;
  border-top-width: 0.5px;
  border-top-style: solid;
  border-top-colo: #736357;
}
.footer-menu {
  height: 65px;
  background-color: #fff;
}
.footermenu-list li {
  display: inline-block;
  line-height: 65px;
}
.footermenu-list li:hover {
  opacity: 0.8;
  text-decoration: underline;
}
.footermenu-list a {
  position: relative;
  padding: 5px 14px 5px 22px;
  color: #736357;
  font-size: 15px;
  white-space: nowrap;
}
.footermenu-list li:first-child a {
  padding-left: 0;
}
.footermenu-list li:first-child a::before {
  display: none;
}
.footermenu-list a::before {
  content: '|';
  position: absolute;
  top: 0;
  left: 0;
  font-size: 11px;
  line-height: 28px;
}
.footer-button {
  position: absolute;
  top: 10px;
  right: 0;
  line-height: 30px;
}
.footer-button-left {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  padding-right: 10px;
  padding-left: 13px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 31px;
  color: #fff;
}
.footer-button-right {
  /*padding-right: 20px;
  min-width: 126px;
  height: 31px;
  font-size: 14px;
  line-height: 31px;
  color: #fff;*/
  position: relative;
  display: inline-block;
  padding-right: 10px;
  padding-left: 13px;
  margin-right: 5px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 31px;
  color: #fff;
}
/*.smallicon01 {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 30px;
  font-size: 14px;
}
.smallicon02 {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 30px;
  font-size: 14px;
}*/
.btn-circle {
  width: 45px;
  height: 45px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  background-color: #6e6e6e;
}
</style>

<i18n>
{
  "ko": {
    "footer_desc1": "63525) 제주 서귀포시 안덕면 산록남로 863 | 대표이사 : 강석현 | 개인정보 보호 책임자 : 김대훈 이사(경영지원담당)<br />통신판매업신고 : 2009-제주안덕-0004호 | 사업자등록번호 : 616-81-14083 | 법인명 : SK핀크스(주)<br />대표번호 : T 064-792-5200 | F 064-792-4901 | 예약 T 064-792-8000 | 예약 M : pinxbooking@skpinx.co.kr<br />"
  },

  "en":{
    "footer_desc1": "863, Sanroknamro, Anduk-myun, Seogwipo-si, Jeju Special Self-Governing Province, 63525 Korea | President : Suk Hyun Kang<br/>Privacy Officer : Director Kim Dae Hoon(Management Support Division)<br/>Sales Reg. No. 2009-Jeju Anduk-0004 | Corporate Reg. No. 616-81-14083 | Corporate Name : SK PINX Co.,Ltd.<br/>A Key Number : Tel. +82-64-792-5200 Fax. +82-64-4901 | Reservation : Tel. +82-64-792-8000 Mail. pinxbooking@skpinx.co.kr<br/>"
  },

  "ja": {
    "footer_desc1": "63525)チェジュとくべつじちどう西帰浦市安徳面山麓南室863 | 代表取締役：カン・ソクヒョン<br/>通信販売業届 : 2009-Jeju Anduk-0004 | 事業者登録番号 : 616-81-14083l法人名: SK ピンクス㈱<br/>代表電話: +82-64-792-5200 ファックス: +82-64-4901 | 予約電話 : +82-64-792-8000 Eメール : pinxbooking@skpinx.com<br/>"
  }
}
</i18n>
