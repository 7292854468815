<template>
  <div class="inner">
    <GnbTop :headers="headers" :logo="require('@/assets/images/main/logo_pinx_color.png')" />
    <GnbWeb
      :headers="headers"
      :white-logo="require('@/assets/images/main/logo_pinx_white.png')"
      :color-logo="require('@/assets/images/main/logo_pinx_color.png')"
    />
  </div>

  <!--  <div class="topLayer">-->
  <!--    <div class="inner">-->
  <!--      <span>adsfasdfasdf</span>-->
  <!--      <GnbBottom-->
  <!--        :is-scroll="isScroll"-->
  <!--        :is-bright="isBright"-->
  <!--        :headers="headers"-->
  <!--        :white-logo="require('@/assets/images/Golf/logo_golf_col_white.png')"-->
  <!--        :color-logo="require('@/assets/images/Golf/logo_golf_col_color.png')"-->
  <!--      />-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import GnbTop from '@/components/GnbTop';
import GnbWeb from '@/components/GnbWeb';
export default {
  name: 'Gnb',
  components: {GnbTop, GnbWeb},
  props: {
    headers: Array,
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.inner {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 10000;
}
</style>
