<template>
  <div class="topNav container-fluid" @mouseleave="gnb_close">
    <div
      :class="{
        whiteRoot: !isTransparent,
        transParentRoot: isTransparent,
        'whiteRoot-open': gnbOpen,
        'whiteRoot-scroll': isScroll,
      }"
    >
      <div
        :class="{
          'header-line': !isScroll || isTransparent,
          'header-line-scroll': isScroll,
          hidden: isTransparent && !gnbOpen,
        }"
      />
      <div :class="{utilLayer: true, hidden: isScroll}">
        <div>
          <router-link to="/">
            <img :src="isTransparent ? whiteLogo : colorLogo" class="topLogo" alt="" />
          </router-link>
          <router-link to="/">
            <img :src="isTransparent ? whiteLogo : colorLogo" class="bottomLogo" alt="" />
          </router-link>
        </div>
        <div :class="{utilMenu: !isTransparent, utilMenuWhite: isTransparent}">
          <div class="utilLeft">
            <div class="lang-list">
              <div class="lang" @click="siteDropDownShow">
                <span class="selected">{{ $t('sitemove') }}</span>
              </div>
              <ul class="select-lang" v-show="isSiteDropDownShow" style="width: 145%" @mouseleave="siteDropDownHide">
                <li>
                  <a href="https://pinxgc.thepinx.co.kr/"><span>PINX GC</span></a>
                </li>
                <li>
                  <a href="https://podo.thepinx.co.kr/"><span>PODO HOTEL</span></a>
                </li>
                <li>
                  <a href="https://theannex.thepinx.co.kr/"><span>THE ANNEX</span></a>
                </li>
                <!--                <li>-->
                <!--                  <a href="https://biotopia.thepinx.co.kr/p/Restaurant"><span>BIOTOPIA</span></a>-->
                <!--                </li>-->
                <li>
                  <a href="https://pleisure.thepinx.co.kr"><span>P-LEISURE</span></a>
                </li>
              </ul>
            </div>
            <div class="item">|</div>
            <div class="lang-list">
              <div class="lang" @click="langDropDownShow">
                <span class="selected">{{ selectedLanguage }}</span>
              </div>
              <ul
                class="select-lang"
                v-show="isLangDropDownShow"
                @click="langDropDownShow"
                @mouseleave="langDropDownHide"
              >
                <li @click="langSelected('KOREAN')">
                  <a href="#"><span>KOREAN</span></a>
                </li>
                <li @click="langSelected('ENGLISH')">
                  <a href="#"><span>ENGLISH</span></a>
                </li>
                <!--                <li @click="langSelected('JAPANESE')">-->
                <!--                  <a href="#"><span>JAPANESE</span></a>-->
                <!--                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div :class="{gnbLayer: true, 'gnbLayer-scroll': isScroll, gnbLayerOpen: gnbOpen}">
        <div>
          <ul class="menu">
            <li v-for="(header, index) in headers" :key="index" @mouseover="gnb_open">
              <a
                :href="header.topMenuLink"
                target="_self"
                :class="{blackMenuHeader: !isTransparent, whiteMenuHeader: isTransparent}"
              >
                <span>{{ header.topMenu }}</span>
              </a>
              <ul class="subMenu" v-if="gnbOpen">
                <li v-for="(sub, index2) in header.subMenu" :key="index2">
                  <router-link :to="sub.link" @click.native="subMenuclick">
                    <span>{{ sub.text }}</span>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store';

export default {
  name: 'GnbBottom',
  props: {
    headers: Array,
    whiteLogo: String,
    colorLogo: String,
  },
  computed: {
    isLogin() {
      return this.$store.getters['Auth/isLoggedIn'];
    },
    loginUser() {
      return this.$store.getters['Auth/loginUser'];
    },
    userName() {
      if (this.loginUser) {
        return this.loginUser.guestName;
      } else {
        return '';
      }
    },
    isTransparent() {
      return this.isRoot && !this.isScroll && !this.gnbOpen;
    },
    selectedNumberMember() {
      console.log('aaa', this.$store.getters['Auth/selectedNumberMember']);
      return this.$store.getters['Auth/selectedNumberMember'];
    },
    optionMemSelect() {
      if (this.loginUser) {
        if (this.loginUser.memInfoArr && this.loginUser.memInfoArr.length > 0) {
          console.log('aaa1', this.loginUser);
          return this.loginUser.memInfoArr.map((a, index) => ({
            value: index,
            text: a.memNo === 'X' ? this.loginUser.homepageGradeName : a.memTypeName + ` ` + a.memNo,
          }));
        } else {
          console.log('aaa2', this.loginUser);
          return null;
        }
      } else {
        console.log('aaa3', this.loginUser);
        return null;
      }
    },
    isShowChangeLang() {
      if (window.location.hostname.startsWith('localhost') || window.location.hostname.startsWith('devel-')) {
        return true;
      } else return false;
    },
  },
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      this.refreshLocale(newVal);
    },
    $route(to, from) {
      if (to.path === '/') {
        this.isRoot = true;
      } else {
        this.isRoot = false;
      }
    },
    selectedNumberMember(newValue, oldValue) {
      this.changeMemNo = newValue;
    },
  },
  data() {
    return {
      isRoot: false,
      isBright: true,
      isWhiteLogo: false,
      isScroll: false,
      isTopLogo: false,
      gnbOpen: false,
      isSiteDropDownShow: false,
      isLangDropDownShow: false,
      selectedLanguage: 'KOREAN',
      changeMemNo: 0,
      isLang: 0,
    };
  },
  created() {
    this.refreshLocale(this.$i18n.locale);
  },
  mounted() {
    if (this.$router.currentRoute.path === '/') {
      this.isMain = true;
    } else {
      this.isMain = false;
    }

    if (this.$router.currentRoute.path === '/') {
      this.isRoot = true;
      this.isWhiteLogo = true;
      this.isBright = true;
    } else {
      this.isRoot = false;
      this.isWhiteLogo = false;
      this.isBright = false;
    }
    window.addEventListener('scroll', this.handleScroll);
    this.changeMemNo = this.selectedNumberMember;
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    refreshLocale(newVal) {
      if (newVal === 'ko') {
        this.isLang = 0;
      } else if (newVal === 'en') {
        this.isLang = 1;
      } else if (newVal === 'ja') {
        this.isLang = 2;
      } else {
        this.isLang = 0;
      }
    },

    updateSelectedMemNo() {
      this.$bvModal
        .msgBoxConfirm('현재 고객 구분을 변경 하시겠습니까?', {
          title: '회원구분 변경',
          okVariant: 'success',
          okTitle: '확인',
          cancelTitle: '취소',
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.$store.commit('Auth/updateSelectedNumberMemberInfo', this.changeMemNo);
          }
        });
    },
    handleScroll() {
      // console.log(window.scrollY, '-------->');
      if (window.scrollY > 0) {
        this.isWhiteLogo = false;
        this.isScroll = true;
      } else {
        this.isBright = true;
        this.isScroll = false;
      }
      console.log(this.isBright, this.isScroll);
    },

    async logout() {
      const confirm = await this.$bvModal.msgBoxConfirm('로그아웃하시겠습니까', {
        title: '로그아웃',
        okVariant: 'primary',
        centered: true,
        okTitle: '확인',
        cancelTitle: '취소',
      });
      if (confirm) {
        this.$store.dispatch('Auth/signOut', '');

        if (this.$router.currentRoute.path !== '/') {
          this.$router.replace('/');
        }
      }
    },
    siteDropDownShow() {
      this.isSiteDropDownShow = !this.isSiteDropDownShow;
    },
    siteDropDownHide() {
      this.isSiteDropDownShow = false;
    },
    langDropDownShow() {
      this.isLangDropDownShow = !this.isLangDropDownShow;
    },
    langDropDownHide() {
      this.isLangDropDownShow = false;
    },
    langSelected(lang) {
      this.selectedLanguage = lang;

      if (lang === 'KOREAN') {
        this.$root.$i18n.locale = 'ko';
      } else if (lang === 'ENGLISH') {
        this.$root.$i18n.locale = 'en';
      } else if (lang === 'JAPANESE') {
        this.$root.$i18n.locale = 'ja';
      }
      if (this.$router.currentRoute.path !== '/') {
        this.$router.replace('/');
      }
    },
    subMenuclick() {
      this.gnbOpen = false;
    },
    gnb_open() {
      this.gnbOpen = true;
      console.log('gnbOpen', this.gnbOpen);
    },
    gnb_close() {
      this.gnbOpen = false;
      console.log('gnbClose', this.gnbOpen);
    },

    headerClick(header) {
      if (header.topMenuLink) {
        this.$router.push(header.topMenuLink);
      }
    },
  },
};
</script>

<style scoped lang="scss">
// RESET CSS
div,
ul,
li {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: 'Noto Sans KR', sans-serif;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

//
a {
  background: transparent;
  text-decoration: none;
  color: inherit;
}

// BOTTOM

.header-line {
  position: absolute;

  //content: '';
  z-index: 2000;
  width: 100%;
  height: 1px;
  top: 11rem;
  background-color: transparent;
  border-bottom: 1px solid #e5e5e5;
}

.header-line-scroll {
  position: fixed;

  z-index: 2000;
  //content: '';
  top: 4rem;
  width: 100%;
  height: 1px;
  background-color: transparent;
  border-bottom: 1px solid #e5e5e5;
}

.utilLayer {
  position: absolute;
  display: flex;

  z-index: 4000;
  //padding-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;

  //background-color: transparent;
  //background-color: red;
  max-width: 1280px;
  //height: 2.5rem;
  height: 7rem;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);

  //transition: all 0.3s ease-out;
  //-webkit-transition: all 0.3s ease-out;
  //-moz-transition: all 0.3s ease-out;
  //-o-transition: all 0.3s ease-out;
}

.transParentRoot {
  font-size: 1rem;
  position: absolute;

  background-color: transparent;
  width: 100%;
  height: auto;
  z-index: 1000;
  //border-bottom: 1px solid blue;
  color: black;

  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.whiteRoot {
  font-size: 10px;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 7.5rem;
  z-index: 100;
  //border-bottom: 1px solid blue;
  color: black;
  //border-bottom: 1px solid #e5e5e5;
}

.whiteRoot-open {
  font-size: 10px;
  position: absolute;
  background-color: white;
  width: 100%;
  height: 11rem;
  z-index: 1000;
  //border-bottom: 1px solid blue;
  color: black;
  border-bottom: 1px solid #e5e5e5;

  //transition: all 0.3s ease-out;
  //-webkit-transition: all 0.3s ease-out;
  //-moz-transition: all 0.3s ease-out;
  //-o-transition: all 0.3s ease-out;
}

.whiteRoot-scroll {
  font-size: 10px;
  position: fixed;
  background-color: white;
  top: 0;
  width: 100%;
  height: auto;
  z-index: 1000;

  //border-bottom: 1px solid blue; //#e9ecef;

  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
}

.gnbLayer {
  //background-color: blue;
  margin-top: 7rem;
  //position: relative;
  position: absolute;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 4rem;
}

.gnbLayerOpen {
  margin-top: 7rem;
  //position: relative;
  position: absolute;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  height: 4rem;
}

.gnbLayer-scroll {
  margin-top: 0rem;
  padding-top: 0rem;
  position: relative;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  width: 100%;
  left: 0%;
  transform: translate(0%, 0%);
}

.menu {
  float: right;
  display: table;
  width: 100%;

  padding-left: 1rem;
  padding-right: 1rem;
  table-layout: fixed;
}

.menu > li {
  position: relative;
  display: table-cell;
  vertical-align: top;
  font-size: 1rem;
  text-align: center;
  max-width: 14.28%;
}

.blackMenuHeader {
  color: #000;
  font-weight: 600;
  position: relative;
  display: inline-block;
  //padding: 0 10px;
  border-bottom: 0;
  height: 4rem;
  padding-top: 1.2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  cursor: pointer;

  transition: all 0s ease;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.whiteMenuHeader {
  color: white;
  font-weight: 600;
  position: relative;
  display: inline-block;
  //padding: 0 10px;
  border-bottom: 0;
  height: 4rem;
  padding-top: 1.2rem;
  padding-left: 1rem;
  padding-right: 1rem;

  cursor: pointer;

  transition: all 0s ease;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.menu > li > a:after {
  display: block;
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;
  width: 1px;
  height: 2px;
  background: transparent;
}

.menu > li:hover > a:after {
  display: block;
  content: '';
  width: 100%;
  left: 0;
  background: #af8c5e;
  cursor: pointer;
}

.subMenu {
  display: block;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.9rem;
  font-weight: 600;
  letter-spacing: -0.02rem;
  line-height: 1.8rem;
  color: #3d3d3d;
}

.subMenu > li:hover {
  color: #af8c5e;
}

/// TOP MENU

.utilMenu {
  width: 100%;
  line-height: 2rem;
  text-align: left;
  font-size: 0.8rem;
  white-space: nowrap;
  color: #666;
  display: flex;
  position: relative;
  .utilLeft {
    display: flex;
    margin-right: auto;
    margin-left: 3rem;
    margin-top: 0.5rem;
  }
  .utilRight {
    margin-left: auto;
    margin-right: 2.5rem;
    margin-top: 0.5rem;
  }

  z-index: 3001;

  transition: all 0s ease;
  transition-property: all;
  transition-duration: 0s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.utilMenuWhite {
  z-index: 3001;

  width: 100%;
  line-height: 2rem;
  text-align: left;
  font-size: 0.8rem;
  white-space: nowrap;
  color: white;
  display: flex;
  position: relative;

  .utilLeft {
    display: flex;
    margin-right: auto;
    margin-left: 3rem;
    margin-top: 0.5rem;
  }
  .utilRight {
    margin-left: auto;
    margin-right: 2.5rem;
    margin-top: 0.5rem;
  }
}

.loginMessage {
  display: flex;
  margin-left: 12px;

  .item {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
  }
  a {
    transition: all 0s ease;
    transition-property: all;
    transition-duration: 0s;
    transition-timing-function: ease;
    transition-delay: 0s;
  }
}

.lang-list {
  display: block;
  position: relative;
  padding-right: 0.6rem;
}

.lang-list > .lang {
  z-index: 2000;
  position: relative;
  max-width: none;
  padding-left: 1rem;
}

.utilMenu > .utilLeft > .lang-list > .lang:after {
  position: absolute;
  top: 0rem;
  right: 1px;
  content: '';
  width: 5px;
  height: 5px;
  border: solid #979797;
  //border: solid white;
  border-width: 0 1px 1px 0;
  margin-top: 0.7rem;
  transform: rotate(-315deg);
  -webkit-transform: rotate(-315deg);
}

.utilMenuWhite > .utilLeft > .lang-list > .lang:after {
  position: absolute;
  top: 0.2rem;
  right: 1px;
  content: '';
  width: 5px;
  height: 5px;
  //border: solid #979797;
  border: solid white;
  border-width: 0 1px 1px 0;
  margin-top: 0.7rem;
  transform: rotate(-315deg);
  -webkit-transform: rotate(-315deg);
}

.lang-list > .lang > span {
  float: right;
  margin-right: 2rem;
}

.select-lang {
  position: absolute;
  top: 1.7rem;
  left: 0;
  z-index: 5000;
  width: 100%;
  background: #fff;
  border: 1px solid #ebebeb;
  padding: 2rem 2rem;
}

.select-lang > li {
  color: black;
}

.select-lang li a:hover {
  text-decoration: underline;
}

.hidden {
  display: none;
}

// MEDIA QUERY

/// LOGO
.topLogo {
  display: none;
  padding-left: 1rem;
  width: 13rem;
  box-sizing: border-box;
}

.bottomLogo {
  //padding-top: 1.3rem;
  //padding-left: 1rem;
  //width: 13rem;
  position: absolute;
  height: 5rem;
  margin-top: 0rem;
  //top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  bottom: 0;
  z-index: 100000;
  //box-sizing: border-box;
  //margin-left: auto;
  //margin-right: auto;
}

.rangeSelectButton {
  align-items: center;
  vertical-align: middle;
  width: 3rem;
  height: 1.6rem;
  font-size: 0.3rem;
  line-height: 1.7rem;
  text-align: center;
  background-color: #3f8397;
  margin-right: 0.5rem;
  padding: 0px;
  color: white;
  border: none;
  outline: none;
}

.loginText {
  padding-left: 1rem;
}

.selectUser {
  height: 1.7rem;
  margin-right: 0.5rem;
  vertical-align: middle;
}

.topNav {
  position: absolute;
  z-index: 10000;
}

@media (max-width: 992px) {
  .topNav {
    display: none;
  }
}
</style>

<i18n>
{
  "ko": {
    "sitemove": "Sites Link",
    "login": "로그인"
  }
,"en": {
  "sitemove": "Sites Link",
  "login": "Login"
}
,"ja": {
  "sitemove": "Sites Link",
  "login": "ログイン"
}
}
</i18n>
